<template>
  <div>
    <nav-bar
      v-if="showBackBtn"
      @onBack="handleBack"
    />
    <div
      v-if="isClose"
    >
      <div v-if="validateParams" class="skuTask">
        <h3>{{ needObscure ? OBSCURE_NAME : skuTaskName }}</h3>
        <div>{{ effectiveTime }}</div>
        <div class="skuTaskUpload">
          <div class="skuTaskUploadFile">
            <div class="upload-top flex-box">
              <div class="toolTip flex1">
                <template v-if="districtInfo">
                  <div>区/县要求：</div>
                  <div>{{ districtInfo }}</div>
                </template>
                <span class="line32" v-if="!afterTaskValid && !beforeNowValid">{{ needObscure ? '测试专用' : note }}</span>
                <div v-else>
                  <p class="line32">上传出错</p>
                  <div v-if="beforeNowValid">
                    <p>标<span style="color: rgb(230, 103, 99);">红</span>文件：录制时间有误，请将手机时间调整为“北京时间”或“自动”后，重新录制上传。</p>
                  </div>
                  <div v-if="afterTaskValid">
                    <p>标<span style="color: rgb(82, 111, 218);">蓝</span>文件：未在任务开始后录制，请重新录制后上传。</p>
                  </div>
                </div>
              </div>
              <van-button
                v-if="showAutoRecordingButton"
                size="small"
                class="auto-recording-button flex0"
                :disabled="isBtnDisabled"
                @click="handleJumpToApp"
              >
                自动录制
              </van-button>
            </div>
            <div class="category" v-if="groceryCategory2Name">{{ groceryCategory2Name }}</div>
            <div>
              <image-collection
                v-if="uploadOptions"
                ref="skuImg"
                :uploadOptions="uploadOptions"
                :accept="accept"
                :task-id="urlParams.userTaskId"
                :job-id="urlParams.jobId"
                :check-repeat="false"
                :check-video-repeat="true"
                :job-start-time="jobStartTime"
                :isCheckTime="false"
                :isCheckVideoTime="true"
                :isDisabled="isBtnDisabled"
                @file-change="onFileChange"
              />
              <div v-else>{{uploadTips}}</div>
            </div>
          </div>
        </div>
        <div class="submit">
          <van-button
            class="skuTaskSubmit"
            :loading="loading"
            loading-text="提交中..."
            @click="handleSubmit"
            :disabled="isBtnDisabled"
          >
            点我提交!
          </van-button>
          <div class="feedback">如无法完成任务，请<span class="linkText" @click="clickFeedback">点击此处</span>反馈</div>
        </div>
      </div>
      <paramsError v-else />
    </div>
    <van-empty
      v-else
      description
    />
  </div>
</template>

<script>
import imageCollection from '../ImageCollection/Index'
import NavBar from '../components/nav-bar.vue'
import paramsError from '../components/paramsError.vue'
import { getTaskDetails, submitCheckCrowdSource } from '@/apis'

import { formatDate } from '@/tools/date'
import { checkUploadImages } from '../shared'
import { Toast, Dialog } from 'vant'
import submitFileMixins from '../mixins/submit-file'
import backListMixins from '../mixins/back-list'

const OBSCURE_KEYWORD = '脱敏'

export default {
  name: 'SkuTask',
  components: {
    imageCollection,
    NavBar,
    paramsError
  },
  mixins: [backListMixins, submitFileMixins],
  data() {
    return {
      urlParams: {},
      skuTaskName: '',
      effectiveTime: '',
      loading: false,
      companyId: '',
      companyName: '',
      isClose: true,
      accept: '',
      taskStartTime: 0,
      taskEndTime: 0,
      note: '请按照要求提交任务',
      cityName: '',
      districtName: '',
      districtInfo: '',
      title: '',
      OBSCURE_NAME: '录屏事件名称',
      needObscure: false,
      recordAppFlag: 1,
      uploadOptions: null,
      uploadTips: '',
      taskType: '',
      jobStartTime: 0,
      afterTaskValid: false,
      beforeNowValid: false,
      groceryCategory2Name: '',
      callAppUrl: '',
      crowdSourceTaskCheck: true
    }
  },
  computed: {
    showAutoRecordingButton(){
      const { name, dataType } = this.urlParams;
      return name && dataType && this.callAppUrl;
    },
    isBtnDisabled(){
      // 众包列表&超管，置灰
      if(this.isCrowdSource && this.isSupper) {
        return true;
      }
      // 众包校验不通过，置灰
      if(!this.crowdSourceTaskCheck){
        return true;
      }
      return false;
    },
  },
  destroyed() {
    document.title = ''
  },
  created() {
    this.checkCrowdSourceTask()
  },
  methods: {
    // 获取任务详情
    async getJobDetails() {
      try {
        const { userTaskId, status } = this.urlParams
        const res = await getTaskDetails({ userTaskId, path: process.env.CHANNEL, status: status ? +status : undefined })
        if (res) {
          const data = res.data || {}
          if (res.code !== 0) return Toast.fail(res.msg || '获取任务详情接口返回错误')
          const time = formatDate(new Date(data.jobEndTime), 'yyyy-MM-dd HH:mm:ss')
          if (data.note) {
            this.note = data.note
          }
          this.needObscure = !!data.isSensitive
          this.cityName = data.cityName || ''
          this.districtName = data.districtName || ''
          this.districtInfo = data.districtList;
          this.skuTaskName = data.name || ''
          if (data.name) {
            if (data?.name?.indexOf(OBSCURE_KEYWORD) > -1 || this.needObscure) {
              document.title = this.OBSCURE_NAME
            } else {
              document.title = data.name
            }
          }
          if (data.recordAppFlag === 2) {
            this.recordAppFlag = 2
          }
          this.effectiveTime = `有效时间至:${time}`
          this.companyId = data.companyId
          this.companyName = data.companyName || ''
          this.taskType = data.taskType
          this.jobStartTime = data.jobStartTime
          this.groceryCategory2Name = data.groceryCategory2Name || ''
          this.callAppUrl = data.callAppUrl;
          if (data.detail) {
            const details = JSON.parse(data.detail)
            this.companyId = details.companyId
          }
        }
      } catch (err) {
        Toast.fail(err.message || '获取任务详情接口请求错误')
      }
    },
    onFileChange() {
      this.taskStartTime = Date.now()
      this.setUnCreateValidFiles()
    },
    setUnCreateValidFiles() {
      const imgs = this.$refs.skuImg.getImagesList()
      this.$nextTick(() => {
        this.afterTaskValid = imgs.filter(item => item.statusCode === 3).length
        this.beforeNowValid = imgs.filter(item => item.statusCode === 4).length
      })
    },
    async handleSubmit() {
      if (this.loading) return
      this.loading = true
      try {
        const imgs = this.$refs.skuImg.getImagesList()
        const submitInfo = await checkUploadImages(imgs)
        if (submitInfo) {
          const verifyValid = await this.$refs.skuImg.checkFileValid()
          if (!verifyValid.valid) {
            Toast.fail(verifyValid.messageType ? verifyValid.message : `${verifyValid.message}，请重新上传后提交`)
            return
          }

          const options = {
            message: this.needObscure ? '确认提交？' : `正在提交${this.skuTaskName}，请再次确认任务城市！`,
            confirmButtonText: '确认提交',
            cancelButtonText: '我再想想',
          }
          await Dialog.confirm(options)
          const checkCsRes = await this.checkCrowdSourceTask()
          if(!checkCsRes) {
            this.loading = false;
            return;
          }
          await this.fetchTaskNew(submitInfo, imgs, 'sku')
        }
      } catch(e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    handleJumpToApp() {
      if (!this.callAppUrl) {
        Toast.fail({
            duration: 4000,
            message: '当前任务类型不支持跳转外包APP',
        })
        return;
      }
      location.href = this.callAppUrl;
    },
    clickFeedback() {
      this.$router.push({ path: '/abnormalFeedback'+ location.search});
    },
    // 校验众包任务有效期(非众包不用校验此接口)
    async checkCrowdSourceTask() {
      if(!this.isCrowdSource || this.isSupper){
        return true;
      }
      try {
        const res = await submitCheckCrowdSource({
          userTaskId: this.urlParams.userTaskId
        })
        if (res && res.code === 0) {
          return true
        }else{
          this.crowdSourceTaskCheck = false;
          Toast.fail('此任务已失效，请返回列表页重新刷新')
          return false
        }
      } catch (error) {
        this.crowdSourceTaskCheck = false;
        Toast.fail(error.msg || '众包任务有效期接口校验失败')
        return false
      }
    },
  },

}
</script>

<style lang="less" scoped>
.skuTask {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px;
  font-size: 16px;
  .skuTaskUpload {
    width: calc(100% - 30px);
    padding: 15px;
    margin-top: 25px;
    font-size: 14px;
    background-color: #e2e2e3;
    border-radius: 20px;
    .skuTaskUploadFile {
      margin-top: 20px;
      .upload-top {
        margin-bottom: 10px;
        .toolTip {
          font-size: 13px;
          .line32 {
            line-height: 32px;
            margin: 0;
          }
        }
        .auto-recording-button {
          color: #fff;
          background-color: #007aff;
          border-radius: 8px;
        }
      }
    }
  }
  .category {
    margin-bottom: 10px;
  }
  .submit {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    margin-top: 20%;
    .skuTaskSubmit {
      width: 100%;
      color: #fff;
      background-color: #007aff;
      border-radius: 16px;
    }
    .feedback {
      padding-top: 10px;
      font-size: 14px;
      text-align: center;
      .linkText{
        font-weight: bold;
        color: #3e7bf3
      }
    }
  }
}
</style>
